export function meetingDoctorsInitScript() {
  return window.meetingdoctors.initialize({
    apiKey: 'f0a66f9288cbc6a8',
    language: 'it',
    landingPage: {
      title:
        'Benvenuto nella sezione di HiPRO dove puoi chattare gratuitamente con un nutrizionista qualificato.',
      subtitle: 'Qualche informazione per te:',
      texts: [
        'In questa sezione potrai ricevere consigli personalizzati sull’alimentazione in base alle tue abitudini sportive condivise direttamente con un nutrizionista.',
        'Questo servizio è offerto da HiPRO di Danone Nutricia S.p.A. Società benefit. ed è gestito in totale autonomia da una terza parte indipendente denominata MeetingDoctors.',
        'I nutrizionisti che forniranno la consulenza tramite il sito web di HiPRO sono indipendenti nell’erogazione della stessa e liberi di consigliare qualsiasi brand o prodotto più idoneo alle tue esigenze nutrizionali e sportive, senza che siano in alcun modo obbligati a consigliare o raccomandare prodotti del brand HiPRO o più in generale di Danone Nutricia S.p.A. Società benefit.',
        'Qualificandosi esclusivamente come un servizio di chat, i nutrizionisti non potranno fornire prescrizioni.',
        'Le informazioni a te richieste nella prossima schermata potranno essere condivise con Danone Nutricia S.p.A. Società benefit, previo tuo consenso espresso. Tuttavia, le informazioni da te condivise direttamente con il nutrizionista, quindi oggetto della consulenza, non saranno in alcun modo e per nessuna ragione condivise con Danone Nutricia S.p.A. Società benefit.',
        'Questo servizio è disponibile Lunedì-Mercoledì-Venerdì : dalle 8:00 alle 12:00 CEST e Martedì-Giovedì : dalle 15.00 alle 19:00 CEST. Si raccomanda di consultare sempre il proprio medico in caso di esigenze specifiche legate alla salute.',
      ],
      buttonText: 'Prosegui',
      linkItems: [
        {
          text: 'Clicca qui per saperne di più su HiPRO DANONE.',
          link: 'https://www.hipro-danone.it/faq/',
          word: 'qui',
        },
      ],
    },

    customLoginEmailDanone: {
      title:
        'Raccontaci qualcosa di te per avere dei consigli nutrizionali mirati al raggiungimento dei tuoi obiettivi',
      buttonText: 'Prosegui',
      fields: [
        {
          name: 'first_name',
          validations: ['required'],
        },
        {
          name: 'email',
          validations: ['emailValid'],
        },
        {
          name: 'phone',
          validations: ['phoneValid'],
        },
        {
          name: 'birthDate',
          validations: ['required', 'age18'],
        },
        {
          name: 'gender',
        },
        {
          name: 'workout',
        },
        {
          name: 'objective',
        },
        {
          name: 'timeWorkout',
        },
      ],
    },
    customLoginEmailDanoneCheck: {
      fields: {
        name: 'lopd',
        text:
          'No I accept the Terms and Conditions and have read the Collection Notice before providing my personal information.',
        actions: [
          {
            word: 'Terms and Conditions',
            href:
              'https://meetingdoctors-terms-and-conditions.s3.eu-central-1.amazonaws.com/CanestenAus/TCCanesten.html',
          },
          {
            word: 'Collection Notice',
            href:
              'https://meetingdoctors-terms-and-conditions.s3.eu-central-1.amazonaws.com/CanestenAus/CollectionNoticeCanesten.html',
          },
        ],
      },
    },

    displayMode: 'widget',
    template: 'beauty',
  });
}
